import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import { Input, Button, Table, Row, Space, message, Popconfirm, Typography, Avatar } from 'antd';
import toTitleCase from 'helpers/toTitleCase';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { getApiWithAuthToken, deleteApiWithAuthToken } from 'api';
import ProtectedComponent from '../../ProtectedComponent';
import { handleDeleteClick } from '../../../helpers/table';
const ManagerForm = lazy(() => import('./ManagerForm'));

const Manager = () => {
  const [managerList, setManagerList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getManagerList = () => {
    getApiWithAuthToken('/api/v1/admin/managers').then(res => {
      if (!res.error && res.data.length) {
        setManagerList(
          res.data.map(each => ({
            id: each.id,
            key: each.id,
            profile_image: each.profile_image,
            code: each.code,
            name: each.name,
            email: each.email,
            lang: toTitleCase(each.lang),
            lang_id: each.lang_id,
            job_title: each.job_title,
            collector: each.collector,
            permissions: each.permissions,
          }))
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
        } else {
          message.error({ content: 'Network Error!' });
        }
        setManagerList([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getManagerList();
  }, []);

  // Edit function

  const [selectedManager, setSelectedManager] = useState({});
  const handleEditClick = record => {
    setSelectedManager(record);
    setIsDrawerVisible(true);
  };

  //Drawer Open function
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setSelectedManager({});
    setIsDrawerVisible(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  // Table functions

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  var searchInput = useRef(null);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'Photo',
      key: 'photo',
      render: (_, record) => <Avatar src={record.profile_image} />,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ...getColumnSearchProps('code'),
      sorter: (a, b) => (a.code ?? '').localeCompare(b.code ?? ''),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
    },
    {
      title: 'Language',
      dataIndex: 'lang',
      key: 'lang',
      ...getColumnSearchProps('lang'),
      sorter: (a, b) => (a.lang ?? '').localeCompare(b.lang ?? ''),
    },
    {
      title: 'Job Title',
      dataIndex: 'job_title',
      key: 'job_title',
      ...getColumnSearchProps('job_title'),
      sorter: (a, b) => (a.job_title ?? '').localeCompare(b.job_title ?? ''),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => {
        if (record.job_title === 'Super Admin') {
          return <></>;
        }
        return (
          <>
            <ProtectedComponent permissionName="Manager" actionName="update">
              <Button size="small" onClick={() => handleEditClick(record)}>
                Edit
              </Button>
            </ProtectedComponent>
            <span> </span>
            <ProtectedComponent permissionName="Manager" actionName="delete">
              <Popconfirm
                title="Are you sure to delete this manager?"
                onConfirm={() =>
                  handleDeleteClick(record.id, '/api/v1/admin/managers/', () => getManagerList())
                }
              >
                <Button danger size="small">
                  Delete
                </Button>
              </Popconfirm>
            </ProtectedComponent>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{ margin: '24px 0' }}>
        <Typography.Title level={3} style={{ marginBottom: '0' }}>
          Manager
        </Typography.Title>
        <ProtectedComponent permissionName="Manager" actionName="create">
          <Button type="primary" onClick={showDrawer}>
            Add
          </Button>
        </ProtectedComponent>
      </Row>
      <ProtectedComponent permissionName="Manager" actionName="read">
        <Table dataSource={managerList} columns={columns} scroll={{ x: 0 }} loading={loading} />
      </ProtectedComponent>
      <Suspense fallback={'Loading...'}>
        <ManagerForm
          handleDrawerClose={handleDrawerClose}
          isDrawerVisible={isDrawerVisible}
          getManagerList={getManagerList}
          manager={selectedManager}
        />
      </Suspense>
    </>
  );
};
export default Manager;
