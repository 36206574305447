import {
    SET_SALES_SUMMARY_TABLE_PARAMS,
    SET_COLLECTIONS_SUMMARY_TABLE_PARAMS,
    SET_ADVANCE_COLLECTIONS_TABLE_PARAMS,
    SET_SETTLEMENTS_TABLE_PARAMS
} from './types';

export const setSalesSummaryTableParams = (params) => async (dispatch) => {
    dispatch({
        type: SET_SALES_SUMMARY_TABLE_PARAMS,
        payload: {
            params: params
        },
    });
};

export const setCollectionsSummaryTableParams = (params) => async (dispatch) => {
    dispatch({
        type: SET_COLLECTIONS_SUMMARY_TABLE_PARAMS,
        payload: {
            params: params
        },
    });
};

export const setAdvanceCollectionsTableParams = (params) => async (dispatch) => {
    dispatch({
        type: SET_ADVANCE_COLLECTIONS_TABLE_PARAMS,
        payload: {
            params: params
        },
    });
};

export const setSettlementsTableParams = (params) => async (dispatch) => {
    dispatch({
        type: SET_SETTLEMENTS_TABLE_PARAMS,
        payload: {
            params: params
        },
    });
};