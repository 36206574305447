export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';

// Table state
export const SET_SALES_SUMMARY_TABLE_PARAMS = 'SET_SALES_SUMMARY_TABLE_PARAMS';
export const SET_COLLECTIONS_SUMMARY_TABLE_PARAMS = 'SET_COLLECTIONS_SUMMARY_TABLE_PARAMS';
export const SET_ADVANCE_COLLECTIONS_TABLE_PARAMS = 'SET_ADVANCE_COLLECTIONS_TABLE_PARAMS';
export const SET_SETTLEMENTS_TABLE_PARAMS = 'SET_SETTLEMENTS_TABLE_PARAMS';
