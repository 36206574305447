import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import { Input, Button, Table, Row, Space, message, Popconfirm, Typography } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { getApiWithAuthToken, deleteApiWithAuthToken } from '../../../api';
import ProtectedComponent from '../../ProtectedComponent';
const CountryManagerForm = lazy(() => import('./CountryManagerForm'));

const CountryManager = () => {
  const [CountryManagerList, setCountryManagerList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCountryManagerList = () => {
    getApiWithAuthToken('/api/v1/admin/companyCountries').then(res => {
      if (!res.error && res.data?.length) {
        setCountryManagerList(
          res.data.map(each => ({
            id: each.id,
            key: each.id,
            country: each.country,
            manager: each.manager,
          }))
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
        } else {
          message.error({ content: 'Network Error!' });
        }
        setCountryManagerList([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getCountryManagerList();
  }, []);

  // Delete function
  function handleDeleteClick(id) {
    message.loading({ content: 'Processing...', key: id });

    deleteApiWithAuthToken(`/api/v1/admin/companyCountries/${id}`).then(res => {
      if (res.error) {
        setTimeout(() => {
          if (res.errors.errno === 1451) {
            message.error({
              content: 'Could not delete!, The CountryManager is being referred in another place',
              key: id,
            });
          } else {
            message.error({
              content: res.errors.sqlMessage,
              key: id,
            });
          }
        }, 1000);
      } else {
        message.success({
          content: 'Deleted!',
          key: id,
        });
        getCountryManagerList();
      }
    });
  }

  // Edit function

  const [selectedCountryManager, setSelectedCountryManager] = useState({});
  const handleEditClick = record => {
    setSelectedCountryManager(record);
    setIsDrawerVisible(true);
  };

  //Drawer Open function
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setSelectedCountryManager({});
    setIsDrawerVisible(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  // Table functions

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  var searchInput = useRef(null);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      ...getColumnSearchProps('country'),
      sorter: (a, b) => (a.country ?? '').localeCompare(b.country ?? ''),
    },
    {
      title: 'Manager',
      dataIndex: 'manager',
      key: 'manager',
      ...getColumnSearchProps('manager'),
      sorter: (a, b) => (a.manager ?? '').localeCompare(b.manager ?? ''),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          <ProtectedComponent permissionName="Locale" actionName="update">
            <Button size="small" onClick={() => handleEditClick(record)}>
              Edit
            </Button>
          </ProtectedComponent>
          <span> </span>
          {/* <Popconfirm
                        title='Are you sure to delete this CountryManager?'
                        onConfirm={() => handleDeleteClick(record.id)}
                    >
                        <Button danger size='small'>
                            Delete
                        </Button>
                    </Popconfirm> */}
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{ margin: '24px 0' }}>
        <Typography.Title level={3} style={{ marginBottom: '0' }}>
          Country Manager
        </Typography.Title>
        {/* <Button type='primary' onClick={showDrawer} >
                    Add
                </Button> */}
      </Row>
      <ProtectedComponent permissionName="Locale" actionName="read">
        <Table
          scroll={{ x: 0 }}
          dataSource={CountryManagerList}
          columns={columns}
          loading={loading}
        />
      </ProtectedComponent>
      <Suspense fallback={'Loading...'}>
        <CountryManagerForm
          handleDrawerClose={handleDrawerClose}
          isDrawerVisible={isDrawerVisible}
          getCountryManagerList={getCountryManagerList}
          CountryManager={selectedCountryManager}
        />
      </Suspense>
    </>
  );
};
export default CountryManager;
