import {
    SET_SALES_SUMMARY_TABLE_PARAMS,
    SET_COLLECTIONS_SUMMARY_TABLE_PARAMS,
    SET_ADVANCE_COLLECTIONS_TABLE_PARAMS,
    SET_SETTLEMENTS_TABLE_PARAMS
} from '../actions/types';

const initialState = {
    salesSummary: {},
    collectionsSummary: {},
    advanceCollections: {},
    settlements: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SALES_SUMMARY_TABLE_PARAMS:
            return {
                ...state,
                salesSummary: action.payload.params
            };
        case SET_COLLECTIONS_SUMMARY_TABLE_PARAMS:
            return {
                ...state,
                collectionsSummary: action.payload.params
            };
        case SET_ADVANCE_COLLECTIONS_TABLE_PARAMS:
            return {
                ...state,
                advanceCollections: action.payload.params
            };
        case SET_SETTLEMENTS_TABLE_PARAMS:
            return {
                ...state,
                settlements: action.payload.params
            };
        default:
            return state;
    }
};

export default reducer;
