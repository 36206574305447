import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import { Input, Button, Table, Row, Space, message, Typography } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { getApiWithAuthToken } from '../../../api';
import toTitleCase from 'helpers/toTitleCase';
const CustomDocumentCodeForm = lazy(() => import('./CustomDocumentCodeForm'));

const CustomDocumentCode = () => {
  const [customDocumentCodeList, setCustomDocumentCodeList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCustomDocumentCodeList = () => {
    getApiWithAuthToken('/api/v1/admin/customDocumentCodes').then(res => {
      if (!res.error && res.data.length) {
        setCustomDocumentCodeList([]);
        res.data.map(each =>
          setCustomDocumentCodeList(customDocumentCodeList => [
            ...customDocumentCodeList,
            {
              id: each.id,
              key: each.id,
              name: toTitleCase(each.name.replaceAll('_', ' ')),
              slug: each.slug,
              slug_position: each.slug_position,
              route_code: each.route_code,
              salesman_code: each.salesman_code,
              company_code: each.company_code,
              year: each.year,
              min_length_1: each.min_length_1,
              min_length_2: each.min_length_2,
              code_separator: each.code_separator,
            },
          ])
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
          setCustomDocumentCodeList([]);
        } else {
          if (res.errors) {
            message.error(res.errors);
            setCustomDocumentCodeList([]);
          } else {
            message.error({ content: 'Network Error!' });
            setCustomDocumentCodeList([]);
          }
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getCustomDocumentCodeList();
  }, []);

  // Edit function

  const [selectedCustomDocumentCode, setSelectedCustomDocumentCode] = useState({});
  const handleEditClick = record => {
    setSelectedCustomDocumentCode(record);
    setIsDrawerVisible(true);
  };

  //Drawer Open function
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setSelectedCustomDocumentCode({});
    setIsDrawerVisible(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  // Table functions

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  var searchInput = useRef(null);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: 'Example',
      dataIndex: 'example',
      render: (_, record) => {
        const code = Array(10);

        code[record.slug_position] = record.slug;
        code[record.route_code] = '008';
        code[record.salesman_code] = '001';
        code[record.company_code] = '002';
        code[record.year] = '22';

        return <>{code.filter(e => e).join(record.code_separator)}</>;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          <Button size="small" onClick={() => handleEditClick(record)}>
            Edit
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{ margin: '24px 0' }}>
        <Typography.Title level={3} style={{ marginBottom: '0' }}>
          Custom Document Code
        </Typography.Title>
      </Row>
      <Table
        scroll={{ x: 0 }}
        dataSource={customDocumentCodeList}
        columns={columns}
        loading={loading}
      />
      <Suspense fallback={'Loading...'}>
        <CustomDocumentCodeForm
          handleDrawerClose={handleDrawerClose}
          isDrawerVisible={isDrawerVisible}
          getCustomDocumentCodeList={getCustomDocumentCodeList}
          customDocumentCode={selectedCustomDocumentCode}
        />
      </Suspense>
    </>
  );
};
export default CustomDocumentCode;
