import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  Row,
  message,
  Descriptions,
  Typography,
  DatePicker,
  Badge,
  Tag,
} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { getApiWithAuthToken } from '../../../api';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useColumnSearchProps, handleTableChange } from '../../../helpers/table';
import { connect } from 'react-redux';
import { setCollectionsSummaryTableParams } from '../../../actions/tableStateAction';

const CollectionsSummary = ({ collectionsSummaryTableParams, setCollectionsSummaryTableParams }) => {
  let history = useHistory();


  // Table states
  const [loading, setLoading] = useState(true);
  const [collectionsSummaryList, setCollectionsSummaryList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    limit: collectionsSummaryTableParams?.sales_code ?? 10,
    page: collectionsSummaryTableParams?.page ?? 1,
    collection_code: collectionsSummaryTableParams?.collection_code ?? null,
    customer_name: collectionsSummaryTableParams?.customer_name ?? null,
    route_name: collectionsSummaryTableParams?.route_name ?? null,
    salesman_name: collectionsSummaryTableParams?.salesman_name ?? null,
    field: collectionsSummaryTableParams?.field ?? null,
    order: collectionsSummaryTableParams?.order ?? null,
  });

  const [startDate, setStartDate] = useState(collectionsSummaryTableParams?.startDate ?? null);
  const [endDate, setEndDate] = useState(collectionsSummaryTableParams?.endDate ?? null);

  const [newStartDate, setNewStartDate] = useState(null);
  const [newEndDate, setNewEndDate] = useState(null);

  const getCollectionsSummaryList = (qp = queryParams) => {
    setLoading(true);
    getApiWithAuthToken(
      `/api/v1/admin/collectionsSummary?limit=${qp.limit}&page=${qp.page}` +
      (startDate ? `&start_date=${startDate}` : '') +
      (endDate ? `&end_date=${endDate}` : '') +
      (qp.collection_code ? `&collection_code=${qp.collection_code}` : '') +
      (qp.customer_name ? `&customer=${qp.customer_name}` : '') +
      (qp.route_name ? `&route=${qp.route_name}` : '') +
      (qp.salesman_name ? `&salesman=${qp.salesman_name}` : '') +
      (qp.field ? `&field=${qp.field}` : '') +
      (qp.order ? `&order=${qp.order}` : '')
    ).then(res => {
      setNewStartDate(moment(res.data.start_date).format('YYYY-MM-DD'));
      setNewEndDate(moment(res.data.end_date).format('YYYY-MM-DD'));
      if (!res.error && res.data?.collections_summary?.length) {
        setTotalCount(res.data.total_count);
        // setStartDate(moment(res.data.start_date).format("YYYY-MM-DD"));
        // setEndDate(moment(res.data.end_date).format("YYYY-MM-DD"));
        setCollectionsSummaryList(
          res.data.collections_summary.map(each => ({
            id: each.id,
            key: each.id,
            collection_code: each.collection_code,
            date: each.date,
            total_amount: each.total_amount,
            customer_name: each.customer_name,
            route_name: each.route_name,
            salesman_name: each.salesman_name,
            trans_type: [...new Set(each.trans_type.map(each => each.toLowerCase()))],
            sod_code: each.sod_code,
            currency: each.currency,
            status: each.status,
          })));
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
        } else {
          message.error({
            content: 'Network Error!',
          });
        }
        setCollectionsSummaryList([]);
        setTotalCount(0);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getCollectionsSummaryList(queryParams);
  }, [startDate, endDate]);

  useEffect(() => {
    setCollectionsSummaryTableParams(
      {
        ...queryParams,
        startDate,
        endDate
      }
    )

  }, [queryParams, startDate, endDate]);

  const columns = [
    {
      title: 'Invoice No',
      dataIndex: 'collection_code',
      key: 'collection_code',
      ...useColumnSearchProps('collection_code', queryParams, setQueryParams, getCollectionsSummaryList),
      sorter: (a, b) => (a.collection_code ?? '').localeCompare(b.collection_code ?? ''),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => (a.date ?? '').localeCompare(b.date ?? ''),
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
      key: 'total_amount',
      sorter: (a, b) => (a.total_amount ?? '').localeCompare(b.total_amount ?? ''),
    },
    {
      title: 'Trans. Type',
      key: 'trans_type',
      render: (_, record) => (
        <>
          {record.trans_type.map(e => {
            return <Tag>{e}</Tag>;
          })}
        </>
      ),
      filters: [
        {
          text: 'Credit',
          value: 'Credit',
        },
        {
          text: 'Cash',
          value: 'Cash',
        },
        {
          text: 'Temporary Credit',
          value: 'Temporary Credit',
        },
      ],
      onFilter: (value, record) =>
        record.trans_type.join(' ').toLowerCase().indexOf(value.toLowerCase()) > -1,
    },
    {
      title: 'Customer',
      dataIndex: 'customer_name',
      key: 'customer_name',
      ...useColumnSearchProps('customer_name', queryParams, setQueryParams, getCollectionsSummaryList),
      sorter: (a, b) => (a.customer_name ?? '').localeCompare(b.customer_name ?? ''),
    },
    {
      title: 'Route',
      dataIndex: 'route_name',
      key: 'route_name',
      ...useColumnSearchProps('route_name', queryParams, setQueryParams, getCollectionsSummaryList),
      sorter: (a, b) => (a.route_name ?? '').localeCompare(b.route_name ?? ''),
    },
    {
      title: 'Salesman',
      dataIndex: 'salesman_name',
      key: 'salesman_name',
      ...useColumnSearchProps('salesman_name', queryParams, setQueryParams, getCollectionsSummaryList),
      sorter: (a, b) => (a.salesman_name ?? '').localeCompare(b.salesman_name ?? ''),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, record) => (
        <>
          <Tag color={record.status === 'printed' ? 'green' : 'red'}>
            {record.status.charAt(0).toUpperCase() + record.status.slice(1)}
          </Tag>
        </>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          <Button
            size="small"
            onClick={() => {
              history.push(`/collections-details?collection_code=${record.collection_code}`);
            }}
          >
            View Details
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{ margin: '24px 0' }}>
        <Typography.Title level={3} style={{ marginBottom: '0' }}>
          Collections Summary
        </Typography.Title>
        <div>
          <Button
            type="primary"
            style={{ marginRight: '16px' }}
            onClick={() => {
              setStartDate(
                moment(
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() - 7
                  )
                ).format('YYYY-MM-DD')
              );
              setEndDate(moment(new Date()).format('YYYY-MM-DD'));
              // setCurrentPage(1);
            }}
          >
            Last Week
          </Button>
          <Button
            type="primary"
            style={{ marginRight: '16px' }}
            onClick={() => {
              setStartDate(moment(new Date()).format('YYYY-MM-DD'));
              setEndDate(moment(new Date()).format('YYYY-MM-DD'));
              // setCurrentPage(1);
            }}
          >
            Today
          </Button>
          <DatePicker.RangePicker
            value={[
              moment(newStartDate).isValid()
                ? moment.utc(newStartDate).local()
                : moment.utc(startDate).local(),
              moment(newEndDate).isValid()
                ? moment.utc(newEndDate).local()
                : moment.utc(endDate).local(),
            ]}
            onChange={(dates, dateStrings) => {
              setNewStartDate(dateStrings[0]);
              setNewEndDate(dateStrings[1]);
            }}
          />
          <Button
            type="primary"
            style={{ marginLeft: '16px' }}
            onClick={() => {
              setStartDate(newStartDate);
              setEndDate(newEndDate);
              // setCurrentPage(1);
              // getCollectionsSummaryList(pageSize, 1);
            }}
          >
            <ReloadOutlined />
          </Button>
        </div>
      </Row>

      <Table
        scroll={{ x: 0 }}
        dataSource={collectionsSummaryList}
        columns={columns}
        loading={loading}
        onChange={handleTableChange}
        pagination={{
          showSizeChanger: true,
          total: totalCount,
          current: queryParams.page,
          pageSize: queryParams.limit,
          size: 'default',
          showTotal: (total, range) => `${range[0]} -${range[1]} of ${total} items`,
        }}
        expandable={{
          expandedRowRender: record => (
            <Descriptions bordered style={{ backgroundColor: '#fff' }}>
              <Descriptions.Item label="Status">
                <Badge
                  status={record.status === 'printed' ? 'success' : 'error'}
                  text={record.status.charAt(0).toUpperCase() + record.status.slice(1)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="SOD Code">{record.sod_code}</Descriptions.Item>
              <Descriptions.Item label="Currency">{record.currency}</Descriptions.Item>
            </Descriptions>
          ),
          rowExpandable: record => record.name !== 'Not Expandable',
        }}
      />
    </>
  );
};

const mapStateToProps = state => ({
  collectionsSummaryTableParams: state.tableState.collectionsSummary,
});

export default connect(mapStateToProps, { setCollectionsSummaryTableParams })(CollectionsSummary);
