import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  Row,
  message,
  Descriptions,
  Typography,
  DatePicker,
  Badge,
  Tag,
} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { getApiWithAuthToken } from '../../../api';
import moment from 'moment';
import { useColumnSearchProps, handleTableChange } from '../../../helpers/table';
import { connect } from 'react-redux';
import { setAdvanceCollectionsTableParams } from '../../../actions/tableStateAction';

const AdvanceCollections = ({ advanceCollectionsTableParams, setAdvanceCollectionsTableParams }) => {

  // Table states
  const [loading, setLoading] = useState(true);
  const [advanceCollectionsList, setAdvanceCollectionsList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    limit: advanceCollectionsTableParams?.sales_code ?? 10,
    page: advanceCollectionsTableParams?.page ?? 1,
    code: advanceCollectionsTableParams?.code ?? null,
    customer_name: advanceCollectionsTableParams?.customer_name ?? null,
    route_name: advanceCollectionsTableParams?.route_name ?? null,
    salesman_name: advanceCollectionsTableParams?.salesman_name ?? null,
    field: advanceCollectionsTableParams?.field ?? null,
    order: advanceCollectionsTableParams?.order ?? null,
  });

  const [startDate, setStartDate] = useState(advanceCollectionsTableParams?.startDate ?? null);
  const [endDate, setEndDate] = useState(advanceCollectionsTableParams?.endDate ?? null);

  const [newStartDate, setNewStartDate] = useState(null);
  const [newEndDate, setNewEndDate] = useState(null);

  const getAdvanceCollectionsList = (qp = queryParams) => {
    setLoading(true);
    getApiWithAuthToken(
      `/api/v1/admin/advanceCollections?limit=${qp.limit}&page=${qp.page}` +
      (startDate ? `&start_date=${startDate}` : '') +
      (endDate ? `&end_date=${endDate}` : '') +
      (qp.code ? `&code=${qp.code}` : '') +
      (qp.customer_name ? `&customer=${qp.customer_name}` : '') +
      (qp.route_name ? `&route=${qp.route_name}` : '') +
      (qp.salesman_name ? `&salesman=${qp.salesman_name}` : '') +
      (qp.field ? `&field=${qp.field}` : '') +
      (qp.order ? `&order=${qp.order}` : '')
    ).then(res => {
      setNewStartDate(moment(res.data.start_date).format('YYYY-MM-DD'));
      setNewEndDate(moment(res.data.end_date).format('YYYY-MM-DD'));
      if (!res.error && res.data?.advance_collections?.length) {
        setTotalCount(res.data.total_count);
        setAdvanceCollectionsList(
          res.data.advance_collections.map(each => ({
            id: each.id,
            key: each.id,
            code: each.code,
            date: each.date,
            amount: each.amount,
            customer_name: each.customer_name,
            route_name: each.route_name,
            salesman_name: each.salesman_name,
            sod_code: each.sod_code,
            currency: each.currency,
            status: each.status,
          })));
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
        } else {
          message.error({ content: 'Network Error!' });
        }
        setAdvanceCollectionsList([]);
        setTotalCount(0);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getAdvanceCollectionsList(queryParams);
  }, [startDate, endDate]);


  useEffect(() => {
    setAdvanceCollectionsTableParams(
      {
        ...queryParams,
        startDate,
        endDate
      }
    )
  }, [queryParams, startDate, endDate]);


  const columns = [
    {
      title: 'Invoice No',
      dataIndex: 'code',
      key: 'code',
      ...useColumnSearchProps('code', queryParams, setQueryParams, getAdvanceCollectionsList),
      sorter: (a, b) => (a.code ?? '').localeCompare(b.code ?? ''),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => (a.date ?? '').localeCompare(b.date ?? ''),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => (a.amount ?? '').localeCompare(b.amount ?? ''),
    },
    {
      title: 'Customer',
      dataIndex: 'customer_name',
      key: 'customer_name',
      ...useColumnSearchProps('customer_name', queryParams, setQueryParams, getAdvanceCollectionsList),
      sorter: (a, b) => (a.customer_name ?? '').localeCompare(b.customer_name ?? ''),
    },
    {
      title: 'Route',
      dataIndex: 'route_name',
      key: 'route_name',
      ...useColumnSearchProps('route_name', queryParams, setQueryParams, getAdvanceCollectionsList),
      sorter: (a, b) => (a.route_name ?? '').localeCompare(b.route_name ?? ''),
    },
    {
      title: 'Salesman',
      dataIndex: 'salesman_name',
      key: 'salesman_name',
      ...useColumnSearchProps('salesman_name', queryParams, setQueryParams, getAdvanceCollectionsList),
      sorter: (a, b) => (a.salesman_name ?? '').localeCompare(b.salesman_name ?? ''),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, record) => (
        <>
          <Tag color={record.status == 'printed' ? 'green' : 'red'}>
            {record.status.charAt(0).toUpperCase() + record.status.slice(1)}
          </Tag>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{ margin: '24px 0' }}>
        <Typography.Title level={3} style={{ marginBottom: '0' }}>
          Advance Collections
        </Typography.Title>
        <div>
          <Button
            type="primary"
            style={{ marginRight: '16px' }}
            onClick={() => {
              setStartDate(
                moment(
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() - 7
                  )
                ).format('YYYY-MM-DD')
              );
              setEndDate(moment(new Date()).format('YYYY-MM-DD'));
              // setCurrentPage(1);
            }}
          >
            Last Week
          </Button>
          <Button
            type="primary"
            style={{ marginRight: '16px' }}
            onClick={() => {
              setStartDate(moment(new Date()).format('YYYY-MM-DD'));
              setEndDate(moment(new Date()).format('YYYY-MM-DD'));
              // setCurrentPage(1);
            }}
          >
            Today
          </Button>
          <DatePicker.RangePicker
            value={[
              moment(newStartDate).isValid()
                ? moment.utc(newStartDate).local()
                : moment.utc(startDate).local(),
              moment(newEndDate).isValid()
                ? moment.utc(newEndDate).local()
                : moment.utc(endDate).local(),
            ]}
            onChange={(dates, dateStrings) => {
              setNewStartDate(dateStrings[0]);
              setNewEndDate(dateStrings[1]);
            }}
          />
          <Button
            type="primary"
            style={{ marginLeft: '16px' }}
            onClick={() => {
              setStartDate(newStartDate);
              setEndDate(newEndDate);
              // setCurrentPage(1);
            }}
          >
            <ReloadOutlined />
          </Button>
        </div>
      </Row>

      <Table
        scroll={{ x: 0 }}
        dataSource={advanceCollectionsList}
        columns={columns}
        loading={loading}
        onChange={handleTableChange}
        pagination={{
          showSizeChanger: true,
          total: totalCount,
          current: queryParams.page,
          pageSize: queryParams.limit,
          size: 'default',
          showTotal: (total, range) => `${range[0]} -${range[1]} of ${total} items`,
        }}
        expandable={{
          expandedRowRender: record => (
            <Descriptions bordered style={{ backgroundColor: '#fff' }}>
              <Descriptions.Item label="Status">
                <Badge
                  status={record.status == 'printed' ? 'success' : 'error'}
                  text={record.status.charAt(0).toUpperCase() + record.status.slice(1)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="SOD Code">{record.sod_code}</Descriptions.Item>
              <Descriptions.Item label="Currency">{record.currency}</Descriptions.Item>
            </Descriptions>
          ),
          rowExpandable: record => record.name !== 'Not Expandable',
        }}
      />
    </>
  );
};

const mapStateToProps = state => ({
  advanceCollectionsTableParams: state.tableState.advanceCollections,
});

export default connect(mapStateToProps, { setAdvanceCollectionsTableParams })(AdvanceCollections);
