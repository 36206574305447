import {connect} from 'react-redux';

const ProtectedComponent = ({user, children, permissionName, actionName}) => {
  if (
    user.role !== 'admin' ||
    (user.permissions.find(p => p.name === permissionName) &&
      user.permissions.find(p => p.name.toLowerCase() === permissionName.toLowerCase())[
        `${actionName}_access`
      ])
  ) {
    return children;
  } else {
    return null;
  }
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(ProtectedComponent);
