import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Layout, Menu, message } from 'antd';
import { connect } from 'react-redux';
import {
  ShoppingOutlined,
  UserOutlined,
  EnvironmentOutlined,
  TransactionOutlined,
  CarOutlined,
  HomeOutlined,
  TagOutlined,
} from '@ant-design/icons';

const { Sider } = Layout;
const { SubMenu } = Menu;

const items = [
  [
    { key: '1', label: 'Area', path: '/area' },
    { key: '2', label: 'Region', path: '/region' },
    {
      key: '3',
      label: 'Currency Conversion',
      path: '/currency-conversion',
    },
    { key: '4', label: 'Country Managers', path: '/country-managers' },
  ],
  [
    // { key: '3', label: 'User', path: '/user' },
    // { key: '4', label: 'Collector', path: '/collector' },
    { key: '5', label: 'Customer Type', path: '/customer-types', permission_name: 'Customer' },
    { key: '6', label: 'Customer', path: '/customer', permission_name: 'Customer' },
    { key: '7', label: 'Manager ', path: '/manager', permission_name: 'Manager' },
    { key: '8', label: 'Salesman', path: '/salesman', permission_name: 'Salesman' },
    { key: '9', label: 'Salesman Log', path: '/salesman/log', permission_name: 'Salesman' },
  ],
  [
    { key: '10', label: 'Product', path: '/product' },
    { key: '11', label: 'Product Category', path: '/product-category' },
    {
      key: '12',
      label: 'Product Subcategory',
      path: '/product-subcategory',
    },
    { key: '13', label: 'Product Multipack ', path: '/product-multipack' },
  ],
  [
    { key: '14', label: 'Price Key', path: '/price-key' },
    { key: '15', label: 'Customer Price', path: '/customer-price' },
    { key: '16', label: 'Route Price ', path: '/route-price' },
    { key: '17a', label: 'Product Price', path: '/product-price' },
    // { key: '16', label: 'Salesman Price', path: '/salesman-price' },
    // { key: '18', label: 'Payment Type ', path: '/payment-type' },
  ],
  [
    { key: '18', label: 'Route', path: '/route', permission_name: 'Route' },
    { key: '19', label: 'Route Journey Plan', path: '/route-journey-plan', permission_name: 'Route' },
    { key: '20', label: 'Route Last Code', path: '/route-last-code', permission_name: 'Route' },
    { key: '21', label: 'Custom Document Code', path: '/custom-document-code' },
    { key: '22', label: 'Branch', path: '/branch', permission_name: 'Branch' },
    { key: '23', label: 'Warehouse', path: '/warehouse', permission_name: 'Warehouse' },
  ],
  [
    { key: '24', label: 'Sales', path: '/sales-summary', permission_name: 'Sales' },
    { key: '25', label: 'Collections', path: '/collections-summary', permission_name: 'Collections' },
    // {key: '24', label: 'Load', path: '/load-summary', permission_name: 'Load'},
    // {key: '25', label: 'Unload', path: '/unload-summary', permission_name: 'Unload'},
    {
      key: '26',
      label: 'Advance Collections',
      path: '/advance-collections',
      permission_name: 'Collections',
    },
    { key: '27', label: 'Settlements', path: '/settlements', permission_name: 'Collections' },
  ],
];

const SiderView = ({ user }) => {
  const history = useHistory();
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState([]);

  const onCollapse = collapsed => {
    setCollapsed(collapsed);
  };
  const onClickMenu = item => {
    items.map(each =>
      each.map(_item => (_item.key === item.key ? history.push(_item.path) : null))
    );
  };

  useEffect(() => {
    if (location.pathname === '/') {
      setSelectedKey(['100']);
    } else {
      items.map(item =>
        item.find(_item => location.pathname === _item.path)
          ? setSelectedKey([item.find(_item => location.pathname === _item.path).key])
          : []
      );
    }
  }, [location]);

  return (
    <Fragment>
      <Sider
        style={window.innerWidth <= 500 ? { zIndex: 500, position: 'absolute', height: '100%' } : { zIndex: 500, }}
        collapsible
        collapsed={collapsed}
        collapsedWidth={0}
        onCollapse={onCollapse}
        theme="light"
        trigger={null}
      >
        <Menu selectedKeys={selectedKey} mode="inline" onClick={onClickMenu}>
          <Menu.Item key="100" icon={<HomeOutlined />} onClick={() => history.push('/')}>
            <span>Dashboard</span>
          </Menu.Item>
          {user.role !== 'admin' || user.permissions.find(p => p.name === 'Locale')?.read_access ? (
            <SubMenu key="sub1" icon={<EnvironmentOutlined />} title="Locale Masters">
              {items[0].map(item => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              ))}
            </SubMenu>
          ) : null}
          <SubMenu key="sub2" icon={<UserOutlined />} title="User Masters">
            {items[1].map(item =>
              item.permission_name ? (
                user.role !== 'admin' ||
                  user.permissions.find(p => p.name === item.permission_name)?.read_access ? (
                  <Menu.Item key={item.key}>{item.label}</Menu.Item>
                ) : null
              ) : (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              )
            )}
          </SubMenu>
          {user.role !== 'admin' ||
            user.permissions.find(p => p.name === 'Product')?.read_access ? (
            <SubMenu key="sub3" icon={<ShoppingOutlined />} title="Product Masters">
              {items[2].map(item => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              ))}
            </SubMenu>
          ) : null}
          {user.role !== 'admin' || user.permissions.find(p => p.name === 'Price')?.read_access ? (
            <SubMenu key="sub4" icon={<TagOutlined />} title="Price Masters">
              {items[3].map(item => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              ))}
            </SubMenu>
          ) : null}
          <SubMenu key="sub5" icon={<CarOutlined />} title="Route Masters">
            {items[4].map(item =>
              item.permission_name ? (
                user.role !== 'admin' ||
                  user.permissions.find(p => p.name === item.permission_name)?.read_access ? (
                  <Menu.Item key={item.key}>{item.label}</Menu.Item>
                ) : null
              ) : (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              )
            )}
          </SubMenu>
          <SubMenu key="sub6" icon={<TransactionOutlined />} title="Transactions">
            {items[5].map(item =>
              item.permission_name ? (
                user.role !== 'admin' ||
                  user.permissions.find(p => p.name === item.permission_name)?.read_access ? (
                  <Menu.Item key={item.key}>{item.label}</Menu.Item>
                ) : null
              ) : (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              )
            )}
          </SubMenu>
        </Menu>
      </Sider>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(SiderView);
