import React, {useState, useEffect, useRef} from 'react';
import {Input, Button, Table, Row, Space, message, Popconfirm, Typography, Tag, Badge} from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';
import {getApiWithAuthToken, postApiWithAuthToken} from '../../../api';
import {useColumnSearchProps, handleDeleteClick, handleTableChange} from '../../../helpers/table';
import ProtectedComponent from '../../ProtectedComponent';

const Customer = () => {
  // Table states
  const [loading, setLoading] = useState(true);
  const [salesmanLogList, setSalesmanLogList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    limit: 10,
    page: 1,
    code: null,
    name: null,
    email: null,
    field: null,
    order: null,
  });

  const getSalesmanLogList = (qp = queryParams) => {
    setLoading(true);
    getApiWithAuthToken(
      `/api/v1/admin/salesmanLogs?limit=${qp.limit}&page=${qp.page}` +
        (qp.name ? `&name=${qp.name}` : '') +
        (qp.code ? `&code=${qp.code}` : '') +
        (qp.email ? `&email=${qp.email}` : '') +
        (qp.field ? `&field=${qp.field}` : '') +
        (qp.order ? `&order=${qp.order}` : '')
    ).then(res => {
      if (!res.error && res.data?.salesmanLogs?.length) {
        setTotalCount(res.data.total_count);
        setSalesmanLogList(
          res.data.salesmanLogs.map(each => ({
            id: each.id,
            key: each.id,
            user_id: each.user_id,
            code: each.code,
            name: each.name,
            email: each.email,
            logged_in_at: each.logged_in_at,
            logged_out_at: each.logged_out_at,
            sync_in: each.sync_in,
            sync_out: each.sync_out,
          }))
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
        } else {
          message.error({
            content: 'Network Error!',
          });
        }
        setSalesmanLogList([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getSalesmanLogList(queryParams);
  }, []);

  function handleForceLogoutClick(user_id) {
    message.loading({
      content: 'Processing...',
      key: user_id,
    });
    postApiWithAuthToken(`/api/v1/admin/auth/force-logout/${user_id}`).then(res => {
      message.loading({
        content: 'Loading...',
        duration: 0.5,
      });
      if (res.error) {
        message.error({
          content: res.errors.sqlMessage,
          key: user_id,
        });
      } else {
        message.success({
          content: 'Logged out!',
          key: user_id,
        });
        getSalesmanLogList();
      }
    });
  }

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      filterSearch: true,
      ...useColumnSearchProps('code', queryParams, setQueryParams, getSalesmanLogList),
      sorter: (a, b) => (a.code ?? '').localeCompare(b.code ?? ''),
    },
    {
      title: 'Name',
      key: 'name',
      ...useColumnSearchProps('name', queryParams, setQueryParams, getSalesmanLogList),
      sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
      render: (_, record) => {
        return (
          <>
            {record.logged_out_at ? <Badge status="error" /> : <Badge status="success" />}
            {record.name}
          </>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...useColumnSearchProps('email', queryParams, setQueryParams, getSalesmanLogList),
      sorter: (a, b) => (a.email ?? '').localeCompare(b.email ?? ''),
    },
    {
      title: 'Logged in at',
      dataIndex: 'logged_in_at',
      key: 'logged_in_at',
    },
    {
      title: 'Logged out at',
      dataIndex: 'logged_out_at',
      key: 'logged_out_at',
    },
    {
      title: 'Sync in',
      key: 'sync_in',
      render: (_, record) => {
        switch (record.sync_in) {
          case 1:
            return <Tag color="green">Success</Tag>;
          case 0:
            return <Tag color="red">Failed</Tag>;
          default:
            return <Tag>Pending</Tag>;
        }
      },
    },
    {
      title: 'Sync out',
      key: 'sync_out',
      render: (_, record) => {
        switch (record.sync_out) {
          case 1:
            return <Tag color="green">Success</Tag>;
          case 0:
            return <Tag color="red">Failed</Tag>;
          default:
            return <Tag>Pending</Tag>;
        }
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          {record.logged_out_at ? (
            <></>
          ) : (
            <ProtectedComponent permissionName="Salesman" actionName="update">
              <Popconfirm
                title="Are you sure to force logout this salesman?"
                onConfirm={() => handleForceLogoutClick(record.user_id)}
              >
                <Button danger size="small">
                  Force Logout
                </Button>
              </Popconfirm>
            </ProtectedComponent>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Row
        justify="space-between"
        style={{
          margin: '24px 0',
        }}
      >
        <Typography.Title
          level={3}
          style={{
            marginBottom: '0',
          }}
        >
          Salesman Log
        </Typography.Title>
      </Row>
      <ProtectedComponent permissionName="Salesman" actionName="read">
        <Table
          scroll={{
            x: 0,
          }}
          dataSource={salesmanLogList}
          columns={columns}
          loading={loading}
          onChange={handleTableChange(queryParams, setQueryParams, getSalesmanLogList)}
          pagination={{
            showSizeChanger: true,
            total: totalCount,
            current: queryParams.page,
            pageSize: queryParams.limit,
            size: 'default',
            showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total} items`,
          }}
        />
      </ProtectedComponent>
    </>
  );
};
export default Customer;
