import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  Row,
  message,
  Descriptions,
  Typography,
  DatePicker,
  Badge,
  Tag,
} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { getApiWithAuthToken } from '../../../api';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useColumnSearchProps, handleTableChange } from '../../../helpers/table';
import { connect } from 'react-redux';
import { setSettlementsTableParams } from '../../../actions/tableStateAction';

const Settlements = ({ settlementsTableParams, setSettlementsTableParams }) => {
  let history = useHistory();

  // Table states
  const [loading, setLoading] = useState(true);
  const [settlementsList, setSettlementsList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    limit: settlementsTableParams?.sales_code ?? 10,
    page: settlementsTableParams?.page ?? 1,
    code: settlementsTableParams?.code ?? null,
    collector_name: settlementsTableParams?.collector_name ?? null,
    route_name: settlementsTableParams?.route_name ?? null,
    salesman_name: settlementsTableParams?.salesman_name ?? null,
    field: settlementsTableParams?.field ?? null,
    order: settlementsTableParams?.order ?? null,
  });

  const [startDate, setStartDate] = useState(settlementsTableParams?.startDate ?? null);
  const [endDate, setEndDate] = useState(settlementsTableParams?.endDate ?? null);

  const [newStartDate, setNewStartDate] = useState(null);
  const [newEndDate, setNewEndDate] = useState(null);

  const getSettlementsList = (qp = queryParams) => {
    setLoading(true);
    getApiWithAuthToken(
      `/api/v1/admin/settlements?limit=${qp.limit}&page=${qp.page}` +
      (startDate ? `&start_date=${startDate}` : '') +
      (endDate ? `&end_date=${endDate}` : '') +
      (qp.code ? `&code=${qp.code}` : '') +
      (qp.collector_name ? `&collector=${qp.collector_name}` : '') +
      (qp.route_name ? `&route=${qp.route_name}` : '') +
      (qp.salesman_name ? `&salesman=${qp.salesman_name}` : '') +
      (qp.field ? `&field=${qp.field}` : '') +
      (qp.order ? `&order=${qp.order}` : '')
    ).then(res => {
      setNewStartDate(moment(res.data.start_date).format('YYYY-MM-DD'));
      setNewEndDate(moment(res.data.end_date).format('YYYY-MM-DD'));
      if (!res.error && res.data.settlements.length) {
        setTotalCount(res.data.total_count);
        // setStartDate(moment(res.data.start_date).format("YYYY-MM-DD"));
        // setEndDate(moment(res.data.end_date).format("YYYY-MM-DD"));
        setSettlementsList(
          res.data.settlements.map(each => ({
            key: each.id,
            code: each.code,
            date: each.date,
            sod_code: each.sod_code,
            collector_code: each.collector_code,
            collector_name: each.collector_name,
            route_code: each.route_code,
            route_name: each.route_name,
            salesman_code: each.salesman_code,
            salesman_name: each.salesman_name,
            total_to_settle: each.total_to_settle,
            total_settled: each.total_settled,
            balance: each.balance,
            currency: each.currency,
            payment_type: each.payment_type,
            payment_type_name: each.payment_type_name,
            status: each.status,
            void: each.void,
          })));
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
        } else {
          message.error({ content: 'Network Error!' });
        }
        setSettlementsList([]);
        setTotalCount(0);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getSettlementsList(queryParams);
  }, [startDate, endDate]);


  useEffect(() => {
    setSettlementsTableParams(
      {
        ...queryParams,
        startDate,
        endDate
      }
    )
  }, [queryParams, startDate, endDate]);

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ...useColumnSearchProps('code', queryParams, setQueryParams, getSettlementsList),
      sorter: (a, b) => (a.code ?? '').localeCompare(b.code ?? ''),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      // fixed: 'left',
      sorter: (a, b) => (a.date ?? '').localeCompare(b.date ?? ''),
    },
    {
      title: 'Total to Settle',
      dataIndex: 'total_to_settle',
      key: 'total_to_settle',
      sorter: (a, b) => (a.total_to_settle ?? '').localeCompare(b.total_to_settle ?? ''),
    },
    {
      title: 'Total Settled',
      dataIndex: 'total_settled',
      key: 'total_settled',
      sorter: (a, b) => (a.total_settled ?? '').localeCompare(b.total_settled ?? ''),
    },
    {
      title: 'Collector',
      dataIndex: 'collector_name',
      key: 'collector_name',
      ...useColumnSearchProps('collector_name', queryParams, setQueryParams, getSettlementsList),
      sorter: (a, b) => (a.collector_name ?? '').localeCompare(b.collector_name ?? ''),
    },
    {
      title: 'Route',
      dataIndex: 'route_name',
      key: 'route_name',
      ...useColumnSearchProps('route_name', queryParams, setQueryParams, getSettlementsList),
      sorter: (a, b) => (a.route_name ?? '').localeCompare(b.route_name ?? ''),
    },
    {
      title: 'Salesman',
      dataIndex: 'salesman_name',
      key: 'salesman_name',
      ...useColumnSearchProps('salesman_name', queryParams, setQueryParams, getSettlementsList),
      sorter: (a, b) => (a.salesman_name ?? '').localeCompare(b.salesman_name ?? ''),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, record) => (
        <>
          <Tag color={record.status === 'printed' ? 'green' : 'red'}>
            {record.status.charAt(0).toUpperCase() + record.status.slice(1)}
          </Tag>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{ margin: '24px 0' }}>
        <Typography.Title level={3} style={{ marginBottom: '0' }}>
          Settlements
        </Typography.Title>
        <div>
          <Button
            type="primary"
            style={{ marginRight: '16px' }}
            onClick={() => {
              setStartDate(
                moment(
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() - 7
                  )
                ).format('YYYY-MM-DD')
              );
              setEndDate(moment(new Date()).format('YYYY-MM-DD'));
              // setCurrentPage(1);
            }}
          >
            Last Week
          </Button>
          <Button
            type="primary"
            style={{ marginRight: '16px' }}
            onClick={() => {
              setStartDate(moment(new Date()).format('YYYY-MM-DD'));
              setEndDate(moment(new Date()).format('YYYY-MM-DD'));
              // setCurrentPage(1);
            }}
          >
            Today
          </Button>
          <DatePicker.RangePicker
            value={[
              moment(newStartDate).isValid()
                ? moment.utc(newStartDate).local()
                : moment.utc(startDate).local(),
              moment(newEndDate).isValid()
                ? moment.utc(newEndDate).local()
                : moment.utc(endDate).local(),
            ]}
            onChange={(dates, dateStrings) => {
              setNewStartDate(dateStrings[0]);
              setNewEndDate(dateStrings[1]);
            }}
          />
          <Button
            type="primary"
            style={{ marginLeft: '16px' }}
            onClick={() => {
              setStartDate(newStartDate);
              setEndDate(newEndDate);
              // setCurrentPage(1);
              // getSettlementsList(pageSize, 1);
            }}
          >
            <ReloadOutlined />
          </Button>
        </div>
      </Row>
      <Table
        scroll={{ x: 0 }}
        dataSource={settlementsList}
        columns={columns}
        loading={loading}
        onChange={handleTableChange}
        pagination={{
          showSizeChanger: true,
          total: totalCount,
          current: queryParams.page,
          pageSize: queryParams.limit,
          size: 'default',
          showTotal: (total, range) => `${range[0]} -${range[1]} of ${total} items`,
        }}
        expandable={{
          expandedRowRender: record => (
            <Descriptions bordered style={{ backgroundColor: '#fff' }}>
              <Descriptions.Item label="Payment Type">
                {record.payment_type_name.charAt(0).toUpperCase() +
                  record.payment_type_name.slice(1)}
              </Descriptions.Item>
              {record.bank_ref_no > 0 ? (
                <Descriptions.Item label="Bank Ref. No.">{record.bank_ref_no}</Descriptions.Item>
              ) : null}
              <Descriptions.Item label="Currency">
                {record.currency.charAt(0).toUpperCase() + record.currency.slice(1)}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                <Badge
                  status={record.status === 'printed' ? 'success' : 'error'}
                  text={record.status.charAt(0).toUpperCase() + record.status.slice(1)}
                />
              </Descriptions.Item>
              {record.void ? (
                <Descriptions.Item label="Void">
                  <Badge status="error" text="Voided" />
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item label="Balance">
                <Badge status={record.balance > 0 ? 'warning' : ''} text={record.balance} />
              </Descriptions.Item>
            </Descriptions>
          ),
          rowExpandable: record => record.name !== 'Not Expandable',
        }}
      />
    </>
  );
};
const mapStateToProps = state => ({
  settlementsTableParams: state.tableState.settlements,
});

export default connect(mapStateToProps, { setSettlementsTableParams })(Settlements);
