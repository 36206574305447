import React, {useState, useEffect, useRef} from 'react';
import {Input, Button, Table, Tag, Row, Space, message, Descriptions, Typography} from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';
import {getApiWithAuthToken} from '../../../api';
import {useLocation} from 'react-router-dom';
const {Text} = Typography;

const SalesDetails = () => {
  const sales_code = new URLSearchParams(useLocation().search).get('sales_code');

  const [loading, setLoading] = useState(true);
  const [salesDetailsList, setSalesDetailsList] = useState([]);
  const [salesSummary, setSalesSummary] = useState({});
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  function getSalesSummary() {
    getApiWithAuthToken(`/api/v1/admin/salesSummary?invoice_no=${sales_code}`).then(res => {
      if (res.data && res.data.length > 0) {
        setSalesSummary(res.data[0]);
      }
    });
  }

  const getSalesDetailsList = (
    limit = 10,
    page = 1,
    invoice_no = null,
    customer = null,
    route = null,
    salesman = null,
    field = null,
    order = null
  ) => {
    getApiWithAuthToken(
      `/api/v1/admin/salesDetails?sales_code=${sales_code}&limit=${limit}&page=${page}` +
        (invoice_no == null ? '' : '&invoice_no=' + invoice_no) +
        (customer == null ? '' : '&customer=' + customer) +
        (route == null ? '' : '&route=' + route) +
        (salesman == null ? '' : '&salesman=' + salesman) +
        (field == null ? '' : '&field=' + field) +
        (order == null ? '' : '&order=' + order)
    ).then(res => {
      if (!res.error && res.data.sales_details.length) {
        setTotalCount(res.data.total_count);
        setSalesDetailsList([]);
        res.data.sales_details.map(each =>
          setSalesDetailsList(salesDetailsList => [
            ...salesDetailsList,
            {
              id: each.id,
              key: each.id,
              detail_code: each.detail_code,
              sales_type: each.sales_type,
              product_code: each.product_code,
              product_name: each.product_name,
              sales_total: each.sales_total,
              base_unit: each.base_unit,
              base_multipack_name: each.base_multipack_name,
              multiplier: each.multiplier,
              multipack_name: each.multipack_name,
              multipack_quantity: each.multipack_quantity,
              price_type: each.price_type,
              price_code: each.price_code,
              cost_price: each.cost_price,
              min_sales: each.min_sales,
              max_discount: each.max_discount,
              product_price: each.product_price,
              sale_price: each.sale_price,
              discount: each.discount,
              vat: each.vat_amt,
            },
          ])
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
          setSalesDetailsList([]);
        } else {
          if (res.errors) {
            message.error(res.errors);
            setSalesDetailsList([]);
          } else {
            message.error({content: 'Network Error!'});
            setSalesDetailsList([]);
          }
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getSalesSummary();
    getSalesDetailsList(pageSize, currentPage);
  }, []);

  // Table functions

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  var searchInput = useRef(null);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex} `}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // confirm();
    if (dataIndex === 'invoice_no') {
      getSalesDetailsList(pageSize, 1, selectedKeys[0]);
    }
    if (dataIndex === 'customer') {
      getSalesDetailsList(pageSize, 1, null, selectedKeys[0]);
    }
    if (dataIndex === 'route') {
      getSalesDetailsList(pageSize, 1, null, null, selectedKeys[0]);
    }
    if (dataIndex === 'salesman') {
      getSalesDetailsList(pageSize, 1, null, null, null, selectedKeys[0]);
    }
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    getSalesDetailsList(pageSize, 1);
    clearFilters();
    setSearchText('');
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPageSize(pagination.pageSize);
    setCurrentPage(pagination.current);
    getSalesDetailsList(
      pagination.pageSize,
      pagination.current,
      filters.invoice_no,
      filters.customer,
      filters.route,
      filters.salesman,
      sorter.field,
      sorter.order
    );
  };

  const columns = [
    // detail_code: each.detail_code,
    // sales_type: each.sales_type,
    // product_name: each.product_name,
    // sales_total: each.sales_total,
    {
      title: 'Detail code',
      dataIndex: 'detail_code',
      key: 'detail_code',
      // ...getColumnSearchProps('detail_code'),
      sorter: (a, b) => (a.detail_code ?? '').localeCompare(b.detail_code ?? ''),
    },
    {
      title: 'Sales type',
      key: 'sales_type',
      render: (_, record) => record.sales_type.toUpperCase(),
      sorter: (a, b) => (a.sales_type ?? '').localeCompare(b.sales_type ?? ''),
      filters: [
        {
          text: 'Sales',
          value: 'Sales',
        },
        {
          text: 'FOC',
          value: 'FOC',
        },
        {
          text: 'GR',
          value: 'GR',
        },
        {
          text: 'Expiry',
          value: 'Expiry',
        },
        {
          text: 'Damage',
          value: 'Damage',
        },
      ],
      onFilter: (value, record) =>
        record.sales_type.toLowerCase().indexOf(value.toLowerCase()) === 0,
    },
    {
      title: 'Product name',
      dataIndex: 'product_name',
      key: 'product_name',
      sorter: (a, b) => (a.product_name ?? '').localeCompare(b.product_name ?? ''),
    },
    {
      title: 'Sales total',
      dataIndex: 'sales_total',
      key: 'sales_total',
      sorter: (a, b) => (a.sales_total ?? '').localeCompare(b.sales_total ?? ''),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{margin: '24px 0'}}>
        <Typography.Title level={3} style={{marginBottom: '0'}}>
          Sales Details
        </Typography.Title>
      </Row>
      <Descriptions bordered style={{backgroundColor: '#fff', marginBottom: '24px'}}>
        <Descriptions.Item label="Invoice no">{salesSummary.invoice_no}</Descriptions.Item>
        <Descriptions.Item label="Date">{salesSummary.date}</Descriptions.Item>
        <Descriptions.Item label="Customer">{salesSummary.customer_name}</Descriptions.Item>
        <Descriptions.Item label="Route">{salesSummary.route_name}</Descriptions.Item>
        <Descriptions.Item label="Salesman">{salesSummary.salesman_name}</Descriptions.Item>
        <Descriptions.Item label="VAT Amount">{salesSummary.vat}</Descriptions.Item>
        <Descriptions.Item label="Net Amount">{salesSummary.net_amount}</Descriptions.Item>
        <Descriptions.Item label="Collected">{salesSummary.paid}</Descriptions.Item>
        <Descriptions.Item label="Balance">{salesSummary.balance}</Descriptions.Item>
        <Descriptions.Item label="Status">
          <Tag color={salesSummary.status == 'printed' ? 'green' : 'red'}>
            {salesSummary.status?.charAt(0).toUpperCase() + salesSummary.status?.slice(1)}
          </Tag>
        </Descriptions.Item>
      </Descriptions>
      <Table
        scroll={{x: 0}}
        dataSource={salesDetailsList}
        columns={columns}
        loading={loading}
        bordered
        onChange={handleTableChange}
        pagination={{
          showSizeChanger: true,
          total: totalCount,
          current: currentPage,
          size: 'default',
          pageSize: pageSize,
          showTotal: (total, range) => `${range[0]} -${range[1]} of ${total} items`,
        }}
        expandable={{
          expandedRowRender: record => (
            <Descriptions bordered style={{backgroundColor: '#fff'}}>
              {record.base_multipack ? (
                <>
                  <Descriptions.Item label="Base Multipack">
                    {record.base_multipack_name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Multiplier">{record.multiplier}</Descriptions.Item>
                </>
              ) : null}
              <Descriptions.Item label="Multipack">{record.multipack_name}</Descriptions.Item>
              <Descriptions.Item label="Multipack Quantity">
                {record.multipack_quantity}
              </Descriptions.Item>
              <Descriptions.Item label="Price Type">
                {record.price_type.charAt(0).toUpperCase() +
                  record.price_type.replace(/_/, ' ').slice(1)}
              </Descriptions.Item>
              <Descriptions.Item label="Price Code">{record.price_code}</Descriptions.Item>
              <Descriptions.Item label="Cost Price">{record.cost_price}</Descriptions.Item>
              <Descriptions.Item label="Min Sales">{record.min_sales}</Descriptions.Item>
              <Descriptions.Item label="Max Discount">{record.max_discount}</Descriptions.Item>
              <Descriptions.Item label="Product Price">{record.product_price}</Descriptions.Item>
              <Descriptions.Item label="Sale Price">{record.sale_price}</Descriptions.Item>
              <Descriptions.Item label="Total Sales">{record.sales_total}</Descriptions.Item>
              <Descriptions.Item label="Discount">
                {record.sale_price * record.multipack_quantity * record.discount}
              </Descriptions.Item>
              <Descriptions.Item label="VAT">{record.vat}</Descriptions.Item>
            </Descriptions>
          ),
          rowExpandable: record => record.name !== 'Not Expandable',
        }}
        summary={() => (
          <Table.Summary>
            <Table.Summary.Row>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell>Sales Total</Table.Summary.Cell>
              <Table.Summary.Cell>
                <Text strong>{salesSummary.invoice_total}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell>Total Discount</Table.Summary.Cell>
              <Table.Summary.Cell>
                <Text strong>{salesSummary.total_discount}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell>VAT Amount</Table.Summary.Cell>
              <Table.Summary.Cell>
                <Text strong>
                  {(salesSummary.invoice_total - salesSummary.total_discount) * salesSummary.vat}
                </Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell>Net Total</Table.Summary.Cell>
              <Table.Summary.Cell>
                <Text strong>{salesSummary.net_amount}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </>
  );
};
export default SalesDetails;
