import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import { Input, Button, Table, Row, Space, message, Popconfirm, Typography } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { getApiWithAuthToken } from '../../../api';
import toTitleCase from 'helpers/toTitleCase';
import { handleDeleteClick } from '../../../helpers/table';
import ProtectedComponent from '../../ProtectedComponent';

const CurrencyConversionForm = lazy(() => import('./CurrencyConversionForm'));

const CurrencyConversion = () => {
  const [currencyConversionList, setCurrencyConversionList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCurrencyConversionList = () => {
    getApiWithAuthToken('/api/v1/admin/currencyConversions').then(res => {
      if (!res.error && res.data?.length) {
        setCurrencyConversionList(
          res.data.map(each => ({
            id: each.id,
            key: each.id,
            from_currency: toTitleCase(each.from_currency),
            to_currency: toTitleCase(each.to_currency),
            from_currency_id: each.from_currency_id,
            to_currency_id: each.to_currency_id,
            conversion: each.conversion,
          }))
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
        } else {
          message.error({ content: 'Network Error!' });
        }
        setCurrencyConversionList([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getCurrencyConversionList();
  }, []);

  // Edit function

  const [selectedCurrencyConversion, setSelectedCurrencyConversion] = useState({});
  const handleEditClick = record => {
    setSelectedCurrencyConversion(record);
    setIsDrawerVisible(true);
  };

  //Drawer Open function
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setSelectedCurrencyConversion({});
    setIsDrawerVisible(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  // Table functions

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  var searchInput = useRef(null);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'From currency',
      dataIndex: 'from_currency',
      key: 'from_currency',
      ...getColumnSearchProps('from_currency'),
      sorter: (a, b) => (a.from_currency ?? '').localeCompare(b.from_currency ?? ''),
    },
    {
      title: 'To currency',
      dataIndex: 'to_currency',
      key: 'to_currency',
      ...getColumnSearchProps('to_currency'),
      sorter: (a, b) => (a.to_currency ?? '').localeCompare(b.to_currency ?? ''),
    },
    {
      title: 'Conversion',
      dataIndex: 'conversion',
      key: 'conversion',
      className: 'table-col-capitalize',
      ...getColumnSearchProps('conversion'),
      sorter: (a, b) => (a.conversion ?? '').localeCompare(b.conversion ?? ''),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          <ProtectedComponent permissionName="Locale" actionName="update">
            <Button size="small" onClick={() => handleEditClick(record)}>
              Edit
            </Button>
          </ProtectedComponent>
          <span> </span>
          <ProtectedComponent permissionName="Locale" actionName="delete">
            <Popconfirm
              title="Are you sure to delete this currency conversion?"
              currencyConversions
              onConfirm={() =>
                handleDeleteClick(record.id, '/api/v1/admin/currencyConversions/', () =>
                  getCurrencyConversionList()
                )
              }
            >
              <Button danger size="small">
                Delete
              </Button>
            </Popconfirm>
          </ProtectedComponent>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{ margin: '24px 0' }}>
        <Typography.Title level={3} style={{ marginBottom: '0' }}>
          Currency Conversion
        </Typography.Title>
        <ProtectedComponent permissionName="Locale" actionName="create">
          <Button type="primary" onClick={showDrawer}>
            Add
          </Button>
        </ProtectedComponent>
      </Row>
      <ProtectedComponent permissionName="Locale" actionName="read">
        <Table
          scroll={{ x: 0 }}
          dataSource={currencyConversionList}
          columns={columns}
          loading={loading}
        />
      </ProtectedComponent>
      <Suspense fallback={'Loading...'}>
        <CurrencyConversionForm
          handleDrawerClose={handleDrawerClose}
          isDrawerVisible={isDrawerVisible}
          getCurrencyConversionList={getCurrencyConversionList}
          currencyConversion={selectedCurrencyConversion}
        />
      </Suspense>
    </>
  );
};
export default CurrencyConversion;
