import React, { useState } from 'react';
import SiderView from './SiderView';
import { connect } from 'react-redux';
import HeaderView from './HeaderView';
import { Layout } from 'antd';
const { Content } = Layout;

const ContentView = ({ children, user }) => {
  const [siderVisible, setSiderVisible] = useState(window.innerWidth > 500);

  return (
    <Layout>
      <HeaderView setSiderVisible={setSiderVisible} siderVisible={siderVisible} />
      <Layout style={{ position: 'relative' }}>
        {siderVisible && <SiderView width={200} />}
        <Layout>
          <Content style={{ padding: 24, margin: 0, minHeight: '100vh' }}>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(ContentView);
