import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import { Input, Button, Table, Row, Space, message, Popconfirm, Typography } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { getApiWithAuthToken } from '../../../api';

import ProtectedComponent from '../../ProtectedComponent';
import { handleDeleteClick } from '../../../helpers/table';
const CustomerTypesForm = lazy(() => import('./CustomerTypesForm'));

const CustomerTypes = () => {
  const [customerTypesList, setCustomerTypesList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCustomerTypesList = () => {
    getApiWithAuthToken('/api/v1/admin/customerTypes').then(res => {
      if (!res.error && res.data?.length) {
        setCustomerTypesList([]);
        res.data.map(each =>
          setCustomerTypesList(customerTypesList => [
            ...customerTypesList,
            {
              id: each.id,
              key: each.id,
              name: each.name,
            },
          ])
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
          setCustomerTypesList([]);
        } else {
          if (res.errors) {
            message.error(res.errors);
            setCustomerTypesList([]);
          } else {
            message.error({ content: 'Network Error!' });
            setCustomerTypesList([]);
          }
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getCustomerTypesList();
  }, []);

  // Edit function

  const [selectedCustomerTypes, setSelectedCustomerTypes] = useState({});
  const handleEditClick = record => {
    setSelectedCustomerTypes(record);
    setIsDrawerVisible(true);
  };

  //Drawer Open function
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setSelectedCustomerTypes({});
    setIsDrawerVisible(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  // Table functions

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  var searchInput = useRef(null);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          <ProtectedComponent permissionName="Customer" actionName="update">
            <Button size="small" onClick={() => handleEditClick(record)}>
              Edit
            </Button>
          </ProtectedComponent>
          <span> </span>
          <ProtectedComponent permissionName="Customer" actionName="delete">
            <Popconfirm
              title="Are you sure to delete this customer type?"
              onConfirm={() =>
                handleDeleteClick(record.id, '/api/v1/admin/customerTypes/', () =>
                  getCustomerTypesList()
                )
              }
            >
              <Button danger size="small">
                Delete
              </Button>
            </Popconfirm>
          </ProtectedComponent>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{ margin: '24px 0' }}>
        <Typography.Title level={3} style={{ marginBottom: '0' }}>
          Customer Types
        </Typography.Title>
        <ProtectedComponent permissionName="Customer" actionName="create">
          <Button type="primary" onClick={showDrawer}>
            Add
          </Button>
        </ProtectedComponent>
      </Row>
      <ProtectedComponent permissionName="Customer" actionName="read">
        <Table scroll={{ x: 0 }} dataSource={customerTypesList} columns={columns} loading={loading} />
      </ProtectedComponent>
      <Suspense fallback={'Loading...'}>
        <CustomerTypesForm
          handleDrawerClose={handleDrawerClose}
          isDrawerVisible={isDrawerVisible}
          getCustomerTypesList={getCustomerTypesList}
          customerTypes={selectedCustomerTypes}
        />
      </Suspense>
    </>
  );
};
export default CustomerTypes;
